// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/hooks/useHasPermission.ts"
);
import.meta.hot.lastModified = "1728902927000";
}
// REMIX HMR END

import { useMemo } from 'react';
import { useAuth } from '~/components/Auth/AuthContext';
import { useWorkspace } from '~/components/Workspaces/WorkspaceContext';

export const useHasPermission = () => {
  const { user }: any = useAuth();
  const { workspaceUserRole = {} }: any = useWorkspace();

  const currentUserPermissions = useMemo(() => {
    const workspacePermissions =
      workspaceUserRole?.workspaceModulePermissionIds.map(
        (item: any) => item?.code,
      ) || [];
    const myPermissions = workspacePermissions.concat(user?.permissions || []);
    return myPermissions;
  }, [workspaceUserRole, user]);

  const checkUserHasPermission = (
    permissions: string | string[],
    checkAll = false,
  ): boolean => {
    if (typeof permissions === 'string' && permissions?.length > 0) {
      const hasPermission = currentUserPermissions?.includes(permissions);
      return hasPermission;
    }
    if (Array.isArray(permissions) && permissions?.length > 0) {
      const hasPermission = checkAll
        ? permissions?.every((permission: string) => {
            return currentUserPermissions?.includes(permission);
          })
        : permissions?.some((permission: string) => {
            return currentUserPermissions?.includes(permission);
          });
      return hasPermission;
    }
    return true;
  };

  return { checkUserHasPermission };
};
